:root {
    --primary: #2a7fb6;
    --primary-dark: #204387;
    --primary-dark-2: #2c3f51;
    --primary-light: #BCC7DB;
    --primary-light-2: hsl(219, 30%, 66%);
    --primary-light-3: #c6cfe0;
    --success: #009245;
    --success-dark: #00642f;
    --success-light: #00aa4f;
    --warning: #ff9800;
    --warning-dark: #c66900;
    --warning-light: #ffba00;
    --danger: #ff0000;
    --danger-dark: #ba000d;
    --danger-light: #ff7961;
    --gray: #e0e0e0;
    --gray-dark: #aeaeae;
    --black: #212121;
    --black-opacity: #0000004d;
    --white: #ffffff;
    --white-opacity: #ffffffb3;
}

body {
    margin: 0;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    font-size: 1.2rem !important;
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

.input-text {
    width: 100% !important;
}

.btn-round {
    border-radius: 10px;
    font-size: 1.1rem;
    font-weight: 400;
}

.text-title {
    font-weight: 600;
    color: var(--primary-dark);
}

.btn-add {
    height: 3.2rem;
    width: 3.2rem;
    background-color: var(--primary-dark);
    cursor: pointer;
}

.btn-add:hover {
    background-color: var(--primary);
}

/* LOGIN */

.login-container {
    width: 100vw;
    height: 100vh;
    background: var(--black);
    background: linear-gradient(90deg, var(--black) 0%, var(--primary) 0%, var(--primary-dark-2) 100%);
}

.input-login {
    background-color: var(--primary-light);
    border-radius: 1rem;
}

.login-text {
    color: var(--primary-dark);
    font-weight: bold;
    cursor: pointer;
}

.btn-login {
    background-color: var(--primary-dark);
    color: var(--white);
    border-radius: 1rem;
    padding: 1% 20%;
    box-shadow: 0px 5px var(--primary-light);
}

.login-card {
    max-width: 350px;
}

/* HOME */

.container-home {
    height: 100vh;
    width: 100vw;
    overflow: hidden;
}

.container-home .home-route {
    display: flex;
    align-items: center;
    padding: 0 2rem;
}

.container-view {
    height: 100%;
    width: 100%;
    padding: 1rem;
}

.navbar-container {
    background-color: var(--white);
    padding: 0% 5%;
    color: var(--white);
}

.input-search {
    border-radius: 1rem;
    background-color: var(--primary-dark);
    border: none;
    color: var(--white);
}

.input-search:focus {
    background-color: var(--primary-dark);
    color: var(--white);
}

.input-search::placeholder {
    color: var(--white);
}

.input-search-icon {
    border-radius: 1rem;
    height: 31px;
    width: 31px;
    margin-right: 3px;
    margin-left: 1px;
    background-color: var(--primary);
    color: var(--white);
}

.ul-content-search {
    max-height: 300px;
    overflow: hidden;
    overflow-y: auto;
    padding: 0;
}

.ul-content-search>li {
    cursor: pointer;
    list-style: none;
    padding: 5px;
    border-radius: 15px;
    border: solid var(--primary-light-2) 1px;
}

.ul-content-search>li:hover {
    color: var(--primary-light);
}

.nav-docs-menu {
    background-color: var(--white);
    height: 60vh;
    width: 100%;
    position: relative;
}

.footer-sidebar {
    height: 25%;
    width: 100%;
    background-color: var(--white);
    overflow-y: auto;
    padding: 5px;
}

.nav-documents {
    max-height: 70%;
    overflow: hidden;
    overflow-y: auto;
}

.nav-docs-menu .menu-click-right {
    position: absolute;
    background-color: var(--white);
    border: solid var(--gray-dark) 0.5px;
    width: 180px;
    height: 270px;
    border-radius: 10px;
    padding: 0% 2%;
    z-index: 1000;
}

.nav-docs-menu .menu-click-right>li {
    list-style: none;
    padding: 3px;
    cursor: pointer;
    color: var(--black);
}

.nav-docs-menu .menu-click-right>li:hover {
    color: var(--primary);
}

.nav-docs-menu .submenu {
    margin-left: 5%;
    padding: 3% 0%;
}

.nav-docs-menu .submenu .title, .nav-docs-menu .submenu .title-focus, .nav-docs-menu .share, .nav-docs-menu .share-focus {
    padding: 0% 0% 0% 5%;
    margin: 1% 0%;
    color: var(--black);
    border-radius: 10px;
}

.nav-docs-menu .submenu .title, .nav-docs-menu .share {
    background-color: var(--white);
}

.nav-docs-menu .submenu .title-focus, .nav-docs-menu .share-focus {
    background-color: var(--primary-light);
}

.nav-docs-menu .submenu .title:hover {
    background-color: var(--primary-light);
}

footer {
    position: absolute;
    bottom: 10px;
    font-size: 0.9rem;
}

.upload-image {
    position: absolute;
    left: 0;
    right: 0;
    top: 35%;
}

div.ag-theme-balham {
    padding: 20px;
}

div.ag-theme-balham, div.ag-theme-balham div.ag-row {
    font-size: 1.2rem !important;
}

.pendings {
    height: 90vh;
    overflow: hidden;
}

.pendings tbody {
    display: block;
    height: 33vh;
    overflow: auto;
}

.ui.table {
    margin: 0px !important;
    height: 90%;
}

.pendings thead, .pendings tbody tr {
    display: table;
    width: 100%;
    table-layout: inherit;
}

.reminders {
    min-width: 300px;
    width: 25vw;
    background-color: var(--white);
    color: var(--primary);
    overflow-y: auto;
    padding: 10px;
    box-shadow: 0px 1px 4px var(--gray-dark);
    border-radius: 25px;
}

.reminders .container-reminders {
    max-height: 300px;
    overflow: hidden;
    overflow-y: auto;
    padding: 0px;
}

.reminders .container-reminders>li {
    list-style: none;
    border: solid var(--primary-light-2) 1px;
    margin: 2px;
    padding: 5px;
    border-radius: 15px;
    word-wrap: break-word;
}

.reminders .container-reminders>li:hover {
    color: var(--primary-light);
}

.reminders .card-session {
    border-top: 0.5px solid var(--primary-dark);
    border-bottom: 0.5px solid var(--primary-dark);
    font-weight: bold;
    padding: 1% 0%;
    cursor: pointer;
}

.modal-view-file {
    width: 100%;
    height: 100%;
    background-color: var(--black);
}

.tree-checkable {
    border: solid var(--primary) 1px;
    height: 14px;
    width: 14px;
    border-radius: 3px;
    margin-right: 4px;
}

.li-dropdown {
    list-style: none;
    cursor: pointer;
    color: var(--black);
}

.li-dropdown-disabled {
    list-style: none;
    cursor: not-allowed;
    color: gray;
}

@media screen and (max-height: 550px) {
    .reminders {
        width: 50vw;
        height: 100vh;
    }

    div.ag-theme-balham {
        padding: 5px;
        height: 65vh !important;
    }

    .nav-docs-menu {
        height: 50vh;
    }

    .nav-docs-menu .submenu {
        margin-left: 15px;
    }

    .pendings tbody {
        height: 30vh;
    }
}

@media screen and (max-height: 768px) {
    .pendings tbody {
        height: 24vh;
    }
}

@media (max-height: 992px) {
    .pendings tbody {
        height: 27vh;
    }
}

@media (max-height: 1200px) {
    .pendings tbody {
        height: 30vh;
    }
}

@media screen and (max-width: 550px) {
    .navbar-container {
        padding: 0%;
    }

    .reminders {
        width: 90vw;
        height: 50vh;
    }

    .container-home .home-route {
        font-size: 0.8rem;
        padding: 5px 0;
    }

    div.ag-theme-balham {
        padding: 5px;
    }

    /* .pendings tbody {
        height: 28vh;
    } */

    .nav-docs-menu .submenu {
        margin-left: 15px;
    }
}

@media screen and (max-width: 768px) {
    .pendings {
        overflow-y: auto;
    }
}